<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
      <b-overlay :show="unitLoad">
        <b-row>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Importer Country" vid="importer_country" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="importer_country"
                slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('tradeTariffApp.importer_country')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                  plain
                  v-model="form.importer_country"
                  :options="tradeTariffCountryList"
                  id="importer_country"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Date" vid="date" rules="required">
              <b-form-group slot-scope="{ valid, errors }" label-cols-sm="4" label-for="date">
                <template v-slot:label>
                  {{ $t('tradeTariffApp.date') }} <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="date"
                  v-model="form.date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :class="errors[0] ? 'is-invalid' : ''"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="HS Code" vid="hs_code" rules="required">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="hs_code"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('tradeTariffApp.hs_code')}}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="form.hs_code"
                  id="hs_code"
                  v-on:blur="getProdName(form.hs_code)"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Product Name (En)" vid="product_name_en" rules="required">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="product_name_en"
                slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                {{ $t('tradeTariffApp.product_name')}} {{ $t('globalTrans.en')}}<span class="text-danger">*</span>
                </template>
                <b-overlay :show="callLoad">
                <b-form-input
                  v-model="form.product_name_en"
                  id="product_name_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                </b-overlay>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Shipper" vid="shipper" rules="required">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="shipper"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('tradeTariffApp.shipper')}}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="form.shipper"
                  id="shipper"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Consignee" vid="consignee" rules="required">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="consignee"
                slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                {{ $t('tradeTariffApp.consignee')}}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="form.consignee"
                  id="consignee"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
            <ValidationProvider name="Port Of Loading" vid="port_of_unloading">
              <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="port_of_unloading"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                {{ $t('tradeTariffApp.port_of_unloading')}}
                </template>
                <v-select
                  id="port_of_unloading"
                  v-model="form.port_of_unloading"
                  multiple
                  :reduce="op => op.value"
                  :options="portsList"
                  label="text"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                </v-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" sm="12">
          </b-col>
          <b-col lg="12" sm="12">
              <ValidationProvider name="Product Description (En)" vid="product_description_en" rules="required">
                  <b-form-group
                      class="row"
                      label-for="product_description_en"
                      slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{$t('tradeTariffApp.product_description')}} {{$t('globalTrans.en')}}<span class="text-danger"> *</span>
                  </template>
                  <b-textarea
                      id="product_description_en"
                      v-model="form.product_description_en"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-textarea>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                      </div>
                  </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col lg="12" sm="12">
              <ValidationProvider name="Attachment" vid="attachment_main" :rules="attachmentx1 ? '' : 'required'">
                <b-form-group slot-scope="{ valid, errors }" label-for="attachment_main" >
                  <template v-slot:label>
                      {{$t('globalTrans.attachment')}}<span class="text-danger">* {{ $t('externalTradeTraiff.attachment_type') }}</span>
                  </template>
                  <div class="d-flex">
                    <b-form-file accept=".pdf, .docx, .doc, .xls, .xlsx" id="attachment_main" v-model="form.file"
                      @change="onFileChange" :state="errors[0] ? false : (valid ? true : null)"
                      :placeholder="attachmentx1 ? attachmentx1 : $t('globalTrans.attachment_placeholder')"
                      :drop-placeholder="$t('externalTradeTraiff.attachment_drop_placeholder')"></b-form-file>
                  </div>
                  <div class="invalid-feedback d-block">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="12">
            <table class="table table-sm table-bordered section-tree-view-table">
                <thead>
                    <tr>
                        <th>{{ $t('tradeTariffApp.from_country') }}</th>
                        <th>{{ $t('tradeTariffApp.import_amount') }}</th>
                        <th>{{ $t('globalTrans.action') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <slot v-for="(item, index) in add_more_files">
                      <tr :key="index">
                        <td>
                            <b-form-select
                              plain
                                  v-model="item.country_id"
                                  :options="tradeTariffCountryList"
                              id="country_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                        </td>
                        <td>
                            <b-form-group label-for="import_amount">
                                <b-form-input type=number v-model="item.import_amount"></b-form-input>
                            </b-form-group>
                        </td>
                        <td  class="text-center" style="width: 15%">
                            <b-button v-if="Object.keys(add_more_files).length >1" @click="addMoreItemRemove(index)" class="text-white btn-danger btn-sm mr-1" title="Delete"><i class="ri-delete-bin-6-line"></i></b-button>
                            <b-button  v-if="Object.keys(add_more_files).length === index + 1" @click="addMoreItem" class="btn-white btn-info" size="sm"><i class="ri-add-line"></i></b-button>
                        </td>
                        </tr>
                    </slot>
                </tbody>
            </table>
        </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-overlay>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { importManagementStore, tariffPortsLstApi, tariffProductApi, importManagementList } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      portsList: [],
      attachmentx1: '',
      unitLoading: false,
      unitLoad: true,
      callLoad: false,
      add_more_files: [
            { country_id: 0, import_amount: null }
      ],
      form: {
        importer_country: '',
        destination_from_country: [],
        date: '',
        hs_code: '',
        shipper: '',
        consignee: '',
        port_of_unloading: [],
        import_amount: 0,
        product_name_en: '',
        product_description_en: '',
        attachment_main: '',
        file: [],
        add_more_files: [
              { country_id: 0, import_amount: null }
        ]
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getEditInfo()
      this.form = tmp
      this.attachmentx1 = this.form.attachment_main.split('/')[3]
      this.getData()
    } else {
      this.unitLoad = false
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    tradeTariffCountryList: function () {
      return this.$store.state.TradeTariffService.commonObj.tradeTariffCountryList.filter(item => item.status === 1)
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localizeSeaPortsList(newVal)
      }
    },
    'form.importer_country': function (newVal, oldVal) {
      if (oldVal !== newVal) {
        this.getPortsList(newVal)
      }
    }
  },
  methods: {
    addMoreItemRemove (index) {
        this.add_more_files.splice(index, 1)
    },
    addMoreItem () {
        this.add_more_files.push({ country_id: 0, import_amount: null })
    },
    getProdName (hsCode) {
      this.callLoad = true
      RestApi.getData(tradeTariffServiceBaseUrl, `${tariffProductApi}/${hsCode}`).then(response => {
        this.callLoad = false
        this.form.product_name_en = response.product_name_en
      })
    },
    getPortsList (countryId) {
        RestApi.getData(tradeTariffServiceBaseUrl, `${tariffPortsLstApi}/${countryId}`).then(response => {
        this.unitLoad = false
        const ports = response.data.filter(obj => obj.status === 1)
        const landPorts = ports.map((obj, index) => {
          return Object.assign({}, obj, {
            value: obj.id,
            text: this.$i18n.locale === 'en' ? obj.port_name_en : obj.port_name_bn,
            text_en: obj.port_name_en,
            text_bn: obj.port_name_bn
          })
        })
        this.portsList = landPorts
      })
    },
    onFileChange (event) {
        const input = event.target
        if (input.files && input.files[0]) {
          const reader = new FileReader()
          reader.onload = (e) => {
            this.form.attachment_main = e.target.result
          }
          reader.readAsDataURL(input.files[0])
        } else {
          this.form.attachment_main = ''
        }
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      this.form.add_more_files = this.add_more_files
        this.form.destination_from_country = []
        this.form.add_more_files.forEach((dataValue) => {
          this.form.destination_from_country.push(dataValue.country_id)
        })
      const formData = this.form
      this.form.id = this.id ? this.id : 0
      result = await RestApi.postData(tradeTariffServiceBaseUrl, importManagementStore, formData)
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getEditInfo () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async getData () {
      const result = await RestApi.getData(tradeTariffServiceBaseUrl, importManagementList)
      if (result.success) {
          this.form = result.data.data.find(item => item.id === parseInt(this.id))
          this.add_more_files = this.form.add_more_files
          if (this.add_more_files.length < 1) {
            this.addMoreItem()
          }
      } else {
          this.add_more_files = []
      }
    }
  }
}
</script>
